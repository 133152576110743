<div class="flex items-center h-full flex-col view_request bg-white">
    <div class="view_request_header bg-white w-full">
        <span>View Request</span>
        <button type="button" (click)="close()"><i class="fas fa-close text-gray-500 text-lg"></i></button>
    </div>
    <div class="view_request_body bg-white w-full">
        <div class="dispatch_container">
            <div class="dispatch_avatar">
                <i class="fa-solid fa-user"></i>
            </div>
            <div class="dispatch_info">
                <p class="item_name">{{schedule?.dispatcher?.name}}</p>
                <p class="descr_title">Dispatcher Requested</p>
            </div>
        </div>
        <div class="driver container_item">
            <div class="driver_container">
                <div class="driver_avatar">
                    <i class="fa-solid fa-user"></i>
                </div>
                <div class="driver_info">
                    <p class="item_name">{{schedule?._local?.driver?.name}}</p>
                    <p class="descr_title">Driver</p>
                </div>
            </div>
            <div class="driver_contact_info">
                <div class="driver_info_item_wr item_wr_email">
                    @if (schedule?._local?.driver?.emails?.length && schedule?._local?.driver?.emails[0]?.value) {
                        <p class="driver_info_item">
                            <i class="fa-solid fa-envelope"></i>
                            <span id="emailDriver">{{truncateString(schedule?._local?.driver?.emails[0]?.value)}}</span>
                            @if (copied == 'emailDriver') {
                                <i class="fa-solid fa-check copied" style="color: #0E9F6E; font-size: 14px;" title="Copied!"></i>
                            } @else {
                                @if (schedule?._local?.driver?.emails?.length) {
                                    <i (click)="copyValue('emailDriver', schedule?._local?.driver?.emails[0]?.value)" class="fa-regular fa-clone cursor-pointer"></i>
                                }
                            }
                        </p>
                    }
                </div>
                <div class="driver_info_item_wr item_wr_phone">
                    @if (schedule?._local?.driver?.phones?.length && schedule?._local?.driver?.phones[0]?.value) {
                        <p class="driver_info_item">
                            <i class="fa-solid fa-phone"></i>
                            <span id="phoneNumber">{{applyMask(schedule?._local?.driver?.phones[0]?.value, inputMasks.phone)}}</span>
                            @if (copied == 'phoneNumber') {
                                <i class="fa-solid fa-check copied" style="color: #0E9F6E; font-size: 14px;" title="Copied!"></i>
                            } @else {
                                @if (schedule?._local?.driver?.phones) {
                                    <i (click)="copyValue('phoneNumber', schedule?._local?.driver?.phones[0]?.value)" class="fa-regular fa-clone cursor-pointer"></i>
                                }
                            }
                        </p>
                    }
                </div>
            </div>
        </div>
        <div class="trip_dates">
            <div class="trip_dates_item container_item">
                <p class="descr_title">Dates</p>
                <p class="trip_dates_value">
                    <span class="trip_first_date">{{schedule?.startDate| date: 'MM/dd/yy'}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="16" viewBox="0 0 51 16" fill="none">
                        <path d="M50.7071 8.70711C51.0976 8.31659 51.0976 7.68342 50.7071 7.2929L44.3431 0.928936C43.9526 0.538412 43.3195 0.538412 42.9289 0.928936C42.5384 1.31946 42.5384 1.95263 42.9289 2.34315L48.5858 8L42.9289 13.6569C42.5384 14.0474 42.5384 14.6805 42.9289 15.0711C43.3195 15.4616 43.9526 15.4616 44.3431 15.0711L50.7071 8.70711ZM-8.74228e-08 9L50 9L50 7L8.74228e-08 7L-8.74228e-08 9Z" fill="#0E9F6E" style="fill:#0E9F6E;fill:color(display-p3 0.0549 0.6235 0.4314);fill-opacity:1;"/>
                    </svg>
                    <span class="trip_last_date">{{schedule?.endDate| date: 'MM/dd/yy'}}</span>
                </p>
            </div>
            <div class="trip_dates_item container_item">
                <p class="descr_title">Request Type</p>
                <p class="req_type">{{schedule?.type == 'DAY_OFF' ? 'Day Off' : schedule?.type}}</p>
            </div>
        </div>
        @if (schedule?.type !== 'DAY_OFF') {
            <div class="available_trucks">
                @if (schedule?.status == 'PENDING') {
                    <p class="available_trucks_header descr_title">Available Trucks</p>
                    <div class="available_trucks_data">
                        <div class="available_trucks_search">
                            <app-input
                                [idInfo]="'trucks_searc'"
                                [label]="'Search'"
                                [options]="{bgGray: true, labelGray: true}"
                                (onInput)="searchEqpt($event)"
                            ></app-input>
                        </div>
                        <div class="available_trucks_items">
                            @for (unit of filteredEqpts; track $index) {
                                <label class="available_trucks_item" [for]="unit?.truck?._id">
                                    <div class="available_truck_names">
                                        <p class="truck">{{unit?.truck?.name}}</p>
                                        <p class="trailer">{{unit?.trailer?.name}}</p>
                                    </div>
                                    <input class="available_truck_radio custom-checkbox" (change)="equipment = unit?.truck" type="radio" [id]="unit?.truck?._id" name="available_truck" />
                                </label>
                            }
                        </div>
                    </div>
                } @else {
                    @if (schedule?.unit?.name) {
                        <div class="available_trucks_data">
                            <p class="descr_title">Truck</p>
                            <p class="item_name">{{schedule?.unit?.name}}</p>
                        </div>
                    }
                }
            </div>
        }
    </div>
    @if (schedule?.status == 'PENDING') {
        <div class="view_request_footer bg-white w-full mt-auto">
            <button class="decline" (click)="decline()">Decline</button>
            <button class="approve" [ngClass]="{'not_allowed_approve': equipment == null && schedule?.type == 'TRIP'}" [disabled]="equipment == null && schedule?.type == 'TRIP'" (click)="approve()">Approve</button>
        </div>
    }
</div>