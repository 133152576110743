import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import { Subject, takeUntil, tap } from 'rxjs'
import { ApiService } from '@app/shared/services/api.service'
import { MaskitoOptions, maskitoTransform } from '@maskito/core'

@Component({
  selector: 'app-view-request-modal',
  templateUrl: './view-request-modal.component.html',
  styleUrls: ['./view-request-modal.component.scss']
})
export class ViewRequestModalComponent implements OnInit, OnDestroy {
  @Input()
  set props (v) {
    if (v) {
      this._props = v
      this.schedule = v?.schedule
      this.filters = v?.filters
      this.currentUser = v?.currentUser
    }
  }
  _props
  result = []
  currentUser
  copied = ''
  type = 'phone'
  inputMasks = {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
  filters  
  equipments = []
  schedule
  filteredEqpts = []
  equipment = null


  destroyed$ = new Subject<boolean>()

  constructor(
    private store: Store<fromCoreStore.State>,
    public apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    if (this.schedule?.status == 'PENDING' && this.schedule?.type == 'TRIP') {
      this.store.dispatch(new fromCoreStore.GetSchedulesEquipments({startDate: this.schedule?.startDate, endDate: this.schedule?.endDate, carrierId: this.currentUser?.company?._id}))
    }

    this.store.select(fromCoreStore.selectSchedulesEquipments).pipe(
      takeUntil(this.destroyed$),
      tap(equipments => {
        if (equipments) {
          let data: any = equipments.filter(n => n?.make && n?.model)
          const transformedEquipments = data.map(item => {
            return {
              truck: {
              _id: item?._id,
                name: `${item?.details || ''} ${item?.year || ''} ${item?.make || ''} ${item?.model || ''}`
              },
              trailer: {
                _id: item?._linkedEqpt?._id,
                name: `${item?._linkedEqpt?.details || ''} ${item?._linkedEqpt?.year || ''} ${item?._linkedEqpt?.make || ''} ${item?._linkedEqpt?.model || ''}`
              }
            }
          })

          this.equipments = transformedEquipments || []
          this.filteredEqpts = transformedEquipments || []
        }
      })
    ).subscribe()

  }

  searchEqpt(value) {
    this.filteredEqpts = this.equipments.filter(e => e?.truck?.name?.toLowerCase().includes(value.toLowerCase()))
  }

  decline() {
    let changes = {
      _id: this.schedule?._id,
      status: 'DECLINED'
    }

    let filter = {
      ...this.filters,
    }

    if (filter?.rangeType) {
      delete filter.rangeType
    }

    if (filter?.driver) {
      filter['driverId'] = filter?.driver?._id
      delete filter.driver
    }

    if (filter?.unit) {
      filter['unitId'] = filter?.unit?._id
      delete filter.unit
    }

    if (filter?.dispatcher) {
      filter['dispatchId'] = filter?.dispatcher?._id
      delete filter.dispatcher
    }
    
    this.store.dispatch(new fromCoreStore.UpdateSchedule({
      changes,
      filter,
      day: this._props?.day
    }))
    this.close()
  }

  approve() {
    let changes = {
      _id: this.schedule?._id,
    }

    let filter = {
      ...this.filters,
    }

    if (filter?.rangeType) {
      delete filter.rangeType
    }

    if (filter?.driver) {
      filter['driverId'] = filter?.driver?._id
      delete filter.driver
    }

    if (filter?.unit) {
      filter['unitId'] = filter?.unit?._id
      delete filter.unit
    }

    if (filter?.dispatcher) {
      filter['dispatchId'] = filter?.dispatcher?._id
      delete filter.dispatcher
    }

    if (this.schedule.type == 'TRIP') {
      changes['unit'] = {
        _id: this.equipment._id,
        name: this.equipment.name
      }
      changes['driver'] = {
        _id: this.schedule?.driver._id,
        name: this.schedule?.driver.name
      }
      changes['status'] = 'APPROVED'
      changes['approveDate'] = new Date()
    }

    if (this.schedule.type == 'DAY_OFF') {
      changes['status'] = 'APPROVED'
    }

    this.store.dispatch(new fromCoreStore.UpdateSchedule({ 
      changes,
      filter,
      day: this._props?.day,
      unChangedFields: {
        daysEarly: this.schedule?.daysEarly || [],
        daysLater: this.schedule?.daysLater || []
      }
     }))
    this.close()
  }

  getMask = (): MaskitoOptions => {
    if (this.inputMasks[this.type]) {
      return {
        mask: this.inputMasks[this.type]
      }
    } else {
      return { mask: new RegExp("") }
    }
  }

  applyMask(input, mask) {
    const inputChars = input.split('')
    let result = ''
    let inputIndex = 0

    for (let i = 0; i < mask.length; i++) {
      if (inputIndex < inputChars.length) {
        if (mask[i] instanceof RegExp) {
          if (mask[i].test(inputChars[inputIndex])) {
            result += inputChars[inputIndex]
            inputIndex++
          }
        } else {
          result += mask[i]
        }
      }
    }

    return result
  }

  close = () => {
    this.store.dispatch(new fromCoreStore.GetSchedulesEquipmentsSuccess({ equipments: [] }))
    this.store.dispatch(new fromModalStoreActions.CloseModal())
    document.body.style.overflow = 'auto';
  }

  copyValue(field, value) {
    const tempInput = document.createElement('input')
    tempInput.value = value
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    this.copied = field
  }

  truncateString(str) {
    if (str?.length > 18) {
      return str.slice(0, 18) + '...'
    }
    return str
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

}
