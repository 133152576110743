<div class="create_contact_header">
    <span>Create New Contact</span>
    <button type="button" (click)="close()"><i class="fas fa-close text-gray-500 text-lg"></i></button>
</div>
<div class="create_contact_body flex items-start" [ngClass]="{'show': showResult}">
    <div style="flex: 1;">
        @if (this.props?.selectType) {
            <div class="info_item relative">
                @if (!valid.type) {
                    <p class="absolute chosse-type" style="z-index: 110;">Select Type</p>
                }
                <app-select
                    [list]="types"
                    [value]="newContact?.type || ''"
                    [placeholder]="'Select Type'"
                    [label]="'Type:'"
                    [isReadOnly]="(!canCreate && selectedContact || (result?.length && hasMatch && selectedContact)) && (hasMatch || selectedContact) && showResult"
                    [keyName]="{key:'name', name:'name'}"
                    (onSave)="setData('type', $event ? $event.toUpperCase() : ''); valid.type = true; canCreate = true"
                ></app-select>
            </div>
        }
        <div class="info_item" [ngClass]="{'in_valid': !valid.name}">
            <app-input
                [idInfo]="'name'"
                [value]="newContact.name" 
                [label]="'Name'"
                [debounceEnable]="true"
                [debounceTime]="600"
                (onChange)="setData('name', $event)"
            ></app-input>
        </div>
        <div class="info_item" [ngClass]="{'in_valid': !valid.phone}">
            <app-input
                [idInfo]="'phone'"
                [label]="'Phone'"
                [type]="'phone'"
                [debounceEnable]="true"
                [disableValidation]="true"
                [debounceTime]="600"
                [options]="{'padding_left_input': false}"
                (onChange)="setData('phone', $event)"
            ></app-input>
        </div>
        <div class="info_item" [ngClass]="{'in_valid': !valid.email}">
            <app-input
                [idInfo]="'email'"
                [label]="'Email'"
                [type]="'email'"
                [debounceEnable]="true"
                [disableValidation]="true"
                [debounceTime]="600"
                [options]="{'padding_left_input': false}"
                (onChange)="setData('email', $event)"
            ></app-input>
        </div>
    </div>
    @if (showResult) {
        <div>
            @if (result?.length) {
                <p class="exist_title">Existing contacts</p>
            }
            <div class="search_result_items" [ngClass]="{
                'show_result': result?.length, 
                'no_show_result': !result?.length
            }">
                @for (item of result; track $index) {
                    <div class="item_right">
                        <div class="search-result-item" (click)="onSelect(item)" [ngClass]="{
                            'focus_item': item?.focus, 
                            'selected': selectedContact?._id == item?._id
                        }">
                            <p class="contact-head">
                                @if(item?.type){
                                    <span class="contact-role">{{item?.type}}</span>
                                }
                                @if(item?.name){
                                    <span class="contact-name">{{item?.name}}</span>
                                }
                            </p>
                            @if(item?.phones?.length){
                                <div class="contact-phones">
                                    <div class="contact-phone">
                                        <i class="fa-solid fa-phone"></i>
                                        <span class="contact-phone-value">{{formatPhone(item?.phones[0]?.value)}}</span>
                                    </div>
                                </div>
                            }
                            @if(item?.emails?.length){
                                <div class="contact-emails">
                                    <div class="contact-email">
                                        <i class="fa-solid fa-paper-plane"></i>
                                        <span class="result_item_gray">{{item?.emails[0]?.value}}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    }
</div>
<div class="create_contact_footer">
    <!-- !canCreate && !selectedContact || (result?.length && hasMatch && !selectedContact) -->
    <button type="button" [disabled]="!canCreate && !selectedContact || (result?.length && hasMatch && !selectedContact)" [ngClass]="{
        'can_create': (canCreate || selectedContact),
        'disable_create': !canCreate && !selectedContact || (result?.length && hasMatch && !selectedContact) || isEmpty()
    }" (click)="create()"
    >
        {{(hasMatch || selectedContact) && showResult ? 'Select Contact' : 'Create'}}
    </button>
</div>