<div class="flex items-center justify-between rounded-t-lg p-4 bg-white">
    <span class="text-base font-medium text-gray-900">Create New Claim</span>
    <button type="button" (click)="close()">
      <i class="fas fa-times"></i>
    </button>
</div>
<div class="p-4 bg-white">
  <div class="">
    <div class="">Select Type</div>
    <div class="types-claims flex items-center mt-2">
      <button type="button" class="claim-type"
        [ngClass]="claimType == 'AUTO' ? 'bg-blue-100' : 'btn_hover'"
        (click)="setType('AUTO')">
        Auto
      </button>

      <button type="button" class="claim-type"
        [ngClass]="claimType == 'TRUCK' ? 'bg-blue-100' : 'btn_hover'"
        (click)="setType('TRUCK')">
        Truck
      </button>

      <button type="button" class="claim-type"
        [ngClass]="claimType === 'OTHER' ? 'bg-blue-100' : 'btn_hover'"
        (click)="setType('OTHER')">
        Other
      </button>
    </div>
  </div>
  <div class="mt-4" *ngIf="claimType == 'AUTO' || claimType == 'OTHER'">
    <div *ngIf="!claimOrder">
      <app-search
        [idInfo]="'order-search'"
        [label]="'Order'"
        [resource]="'ORDER'"
        [method]="'getAll'"
        [options]="{focus: true}"
        [filter]="{withoutClaim: true}"
        (onChange)="setClaimOrder($event)"
      ></app-search>
    </div>
    <span *ngIf="claimOrder" class="text-base font-medium bg-gray-100 rounded-md px-2 py-1">
      Order#
      <a
        class="ml-2 mr-2"
        routerLink="/quotes/{{ claimOrder?._id }}"
        target="_blank"
      >
        {{ claimOrder?.quoteNumber }}
        <span *ngIf="claimOrder?.ordering">.{{ claimOrder?.ordering }}</span>
      </a>
      <app-btn-confirm
        (onConfirm)="removeOrderLink()"
      ></app-btn-confirm>
    </span>
  </div>
  <div *ngIf="claimType != 'AUTO' || claimantAccountId" [ngClass]="{'mt-4': claimType !== 'OTHER', 'mt-2': claimType == 'OTHER'}">
    @for(claimantId of claimantContacts; track $index){
      <div class="claimant-info mt-2 p-3 border border-gray-300 rounded-lg relative">
        <button *ngIf="claimType != 'AUTO'" class="remove-claimant absolute top-2" type="button" (click)="removeClaimant()">
          <i class="fas fa-times text-gray-600" style="font-size: 12px; right: 10px;"></i>
        </button>
        <app-contact-details
          [contact]="getContactById(claimantId)"
          [main]="claimantAccountId"
          [fields]="{
            name: { action: 'r' },
            type: { action: 'r' },
            phones: { action: 'r' },
            emails: { action: 'r' },
            contact: false,
            edit: false
          }"
        >
        </app-contact-details>
      </div>
    }
    <app-search
      *ngIf="!claimantAccountId"
      [resource]="'CONTACT'"
      [label]="'Claimant'"
      [idInfo]="'claim_Claimant'"
      [options]="{noFocus: true}"
      [method]="'search'"
      [filter]="{type: claimantAccountId ? '' : 'ALL', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
      (onChange)="addClaimantContact(claimantAccountId ? 'contacts' : 'accountId', $event)"
    ></app-search>
  </div>
</div>
<div class="bg-white text-right p-4 create-claim-footer">
  <button (click)="onSubmit()" class="create_claim_btn">
    Create Claim
  </button>
</div>
